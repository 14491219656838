.create-job__form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 30px;
    align-items: center;
}

.create-job__text-input {
    width: 70%;
    border-radius: 30px;
    padding: 10px;
    border: 1px solid #000;
}

.create-job__button {
    width: 200px;
    border-radius: 30px;
    border: 1px solid #000;
    padding: 10px;
    background-color: blue;
    color: #fff;
    font-size: 15px;
}