.header {
  padding-top: 30px;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 44px;
  flex: 0 0 auto;
}

.header__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header__logo-text {
  font-family: "Conthrax";
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 65px;

  color: #000000;
}

.header__navigation {
  padding-top: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18%;
  align-items: flex-start;
  flex-wrap: wrap;
}

.header__link {
    color: #383838;
    transition: all 1s;
}

.header__link:hover {
    color: #0A72CD;
    filter: drop-shadow(10px 10px 10px #0A72CD);
}

.header__link_active {
    filter: drop-shadow(10px 10px 10px #0A72CD);
}

@media(max-width: 800) {
}

@media(max-width: 560px) {
    .header__navigation {
        padding-top: 20px;
    }
}

@media(max-width: 510px) {
    .header__logo-text {
        font-size: 32px;
        line-height: 40px;
    }

    .header__logo-image {
        max-width: 40px;
        max-height: 60px;
    }

    .header__link {
        font-size: 24px;
        line-height: 30px;
    }
}