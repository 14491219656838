.error {
    height: 100%;
  background-repeat: no-repeat;
  background-image: url("../images/bg.jpg");
  background-size: cover;
  background-position: center;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 50px;
  color: #ffffff;

  text-shadow: 1px 1px 5px #000;
}

.error__text {
  font-size: 66px;
  line-height: 79px;
}

.header__logo-text_white {
  color: white;
}

.error__content {
    display: flex;
    flex-direction: column;
    padding: 10% 30px 0;
}

.error__footer {
    padding-top: 5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.error__footer-text {
    max-width: 60%;
}

.error__footer-button {
    padding: 5px 25px;
    background-color: #0A72CD;
    border-radius: 30px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    margin-top: 10px;
}

@media (max-width: 880px) {
    .error {
        font-size: 26px;
        line-height: 30px;
    }

    .error__footer-text {
        max-width: 100%;
        text-align: center;
    }

    .error__text {
        font-size: 46px;
        line-height: 59px;
      }

      .error__footer {
          justify-content: center;
      }
}

@media (max-height: 400px) {
    .error {
        background-image: none;
        background-color: #ffffff;
    }
}
