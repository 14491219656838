.admin-posts {
    padding-top: 50px;
}

.admin-post__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    padding-bottom: 20px;
}

.admin-post__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-posts__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-post__button {
    background-color: blue;
    padding: 15px;
    color: white;
    border-radius: 15px;
    text-transform: uppercase;
    transition: all .3s;
}

.admin-post__button:hover {
    background-color: inherit;
    color: blue;
} 