.jobs {
  padding-top: 50px;
  font-family: "Open Sans";
  font-style: normal;
}

.jobs__title {
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #000000;
}

.jobs__item {
    width: 70%;
    margin: 10px auto;
    border: 2px solid #0A72CD;
    border-radius: 30px;
    padding: 20px 25px;
    box-shadow: 5px 1px 10px #0A72CD;
}

.jobs__name {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #0A72CD;
}

.jobs__description {
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
}

.jobs__payment {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #0A72CD;
    font-style: italic;
}

.jobs__button {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #0A72CD;
    text-transform: uppercase;
    text-decoration: underline;
}

@media (max-width: 830px) {
    .jobs__title {
        font-size: 32px;
        line-height: 40px;
      }
}

@media (max-width: 600px) {
    .jobs__item {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .jobs__title {
        font-size: 26px;
        line-height: 30px;
    }
    .jobs__name {
        font-size: 20px;
        line-height: 30px;
    }
    
    .jobs__description {
        font-size: 15px;
        line-height: 20px;
    }
    
    .jobs__payment {
        font-size: 14px;
        line-height: 20px;
    }
    
    .jobs__button {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}