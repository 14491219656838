@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Light.woff2') format('woff2') url('OpenSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-LightItalic.woff2') format('woff2') url('OpenSans-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Regular.woff2') format('woff2') url('OpenSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Italic.woff2') format('woff2') url('OpenSans-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Medium.woff2') format('woff2') url('OpenSans-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-MediumItalic.woff2') format('woff2') url('OpenSans-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-SemiBold.woff2') format('woff2') url('OpenSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-SemiBoldItalic.woff2') format('woff2') url('OpenSans-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Bold.woff2') format('woff2') url('OpenSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-BoldItalic.woff2') format('woff2') url('OpenSans-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-ExtraBold.woff2') format('woff2') url('OpenSans-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-ExtraBoldItalic.woff2') format('woff2') url('OpenSans-ExtraBoldItalic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}
