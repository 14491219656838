.create-post__inner {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
}

.create-post__image-button {
  max-width: 300px;
  width: 100%;
  background-color: green;
  padding: 10px 15px;
  border: none;
  border-radius: 30px;
  color: #fff;
  transition: all 0.5s;
}

.create-post__image-button:hover {
  background-color: transparent;
  color: green;
}

.create-post__image-button_red {
  background-color: red;
}

.create-post__image-button_red:hover {
  color: red;
}

.create-post__text-input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid #000;
    max-height: 400px;
    height: 100%;
}

.create-post__text {
    width: 100%;
    border: 1px solid #000;
}

.create-post__image {
    width: 100%;
}
