.admin-certificates {
  padding-top: 50px;
}

.admin-certificates__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.admin-certificates__title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;
}

.admin-certificates__create-button {
  background-color: blue;
  padding: 15px;
  color: white;
  border-radius: 15px;
  text-transform: uppercase;
  transition: all 0.3s;
}

.admin-certificates__create-button:hover {
  background-color: inherit;
  color: blue;
}

.admin-certificates__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  gap: 10px;
}

.admin-certificates__item {
  max-width: 600px;
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.admin-certificates__item-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: start;

  max-width: 350px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-certificates__delete-button {
    background-color: #ff0000;
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
    cursor: pointer;
  }
  
  .admin-certificates__delete-button:hover {
    background-color: transparent;
    color: #ff0000;
  }
  
  
  @media (max-width: 400px) {
    .admin-certificates__item-title {
      font-size: 16px;
      max-width: 250px;
    }
    
  }
