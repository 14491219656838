.login {
    width: 100%;
    height: 100%;
    padding-top: 100px;
}

.login__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.login__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
}

.login__form-item-input {
    max-width: 300px;
    width: 100%;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #000;
}

.login__form-button {
    max-width: 300px;
    width: 100%;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #000;
    background-color: #0A72CD;
    color: #fff;
    transition: all .3s;
}

.login__form-button:hover {
    box-shadow: 5px 5px 10px #000;
}

.login__form-button:active {
    background-color: #008000;
}

.login__title {
    text-align: center;
}

.login__error {
    font-size: 20px;
    color: #ff0000;
    position: absolute;
    bottom: -35%;
}
