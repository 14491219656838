.contact {
  padding-top: 50px;
}

.contact__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__title {
  padding-bottom: 10px;
  font-family: "Conthrax";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 50px;
  color: #000;
}

.contact__title_blue {
  color: #0a72cd;
}

.contact__subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #383838;
  padding-bottom: 50px;
}

.contact__form {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.contact__form-item {
  max-width: 20%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact__form-item-input {
  padding: 20px 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  background: #ffffff;
  border: 2px solid rgba(56, 56, 56, 0.48);
  border-radius: 15px;
  outline: 0;
}

.contact__form-item-input:focus {
  border-color: #0a72cd;
}

.contact__form-item-input:focus::placeholder {
    color: #0a72cd;
  }
  

.contact__error {
  position: absolute;
  color: #ff0000;
  top: -40px;
}

.contact__form-button {
  border: none;
  padding: 20px 80px;
  background-color: #0a72cd;
  border-radius: 15px;
  color: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
}

@media (max-width: 1100px) {
    .contact__form {
        flex-direction: column;
    }

    .contact__form-item {
        max-width: 100%;
    }

    .contact__error {
        position: absolute;
        color: #ff0000;
        top: -20px;
      }
}

@media (max-width: 360px) {
    .contact__title {
        font-family: "Conthrax";
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 40px;
        color: #000;
      }
}

.invisible {
    display: none;
}

.contact__form_submit {
    width: 100%;
    height: 50px;
    position: relative;
}

.contact__form_submit::before {
    width: 100%;
    height: 100%;
    padding-top: 12px;
    text-transform: uppercase;
    text-align: center;
    content: "Отправлено";
    position: absolute;
    border: 1px solid #0a72cd;
    top: 0;
    left: 0;
    color: #0a72cd;
}